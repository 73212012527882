import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import { toast } from "react-hot-toast"
import tempimg from "../img/tempimg.jpg"
import styles from "../styles/AllTests.module.css"
import jsPDF from "jspdf"
import html2canvas from "html2canvas"

function TemperamentsResults() {
  const [temperament, setTemperament] = useState([])
  const [userId, setUserId] = useState("")
  const [username, setUsername] = useState("")
  const [_id, setId] = useState("")
  const [email, setEmail] = useState("")
  const [expandedTemperaments, setExpandedTemperaments] = useState([])
  const [showTemperament, setShowTemperament] = useState(false)
  const [additionalInfo, setAdditionalInfo] = useState([])
  const [listOfTraitProperties, setListOfTraitProperties] = useState("")
  const [listOfSkillPreferencesProperties, setListOfSkillPreferencesProperties] = useState("")
  const [listOfCareerPreferencesProperties, setListOfCareerPreferencesProperties] = useState("")
  const listOfTraitPropertiesArray = (listOfTraitProperties || "").split(",")
  const listOfSkillPreferencesPropertiesArray = (listOfSkillPreferencesProperties || "").split(",")
  const listOfCareerPreferencesPropertiesArray = (listOfCareerPreferencesProperties || "").split(",")

  const [expandedCards, setExpandedCards] = useState({
    traits: false,
    skills: false,
    careers: false,
  })

  const navigate = useNavigate()

  useEffect(() => {
    const token = localStorage.getItem("token")
    if (!token) {
      // Redirect to login page if user is not logged in
      navigate("/")
    } else {
      // Fetch user ID from session storage
      const newUuid = localStorage.getItem("uuid")
      const username = localStorage.getItem("username")
      const _id = localStorage.getItem("_id")
      const email = localStorage.getItem("email")
      setUserId(newUuid)
      setUsername(username)
      setId(_id)
      setEmail(email)
    }
  }, [navigate])

  useEffect(() => {
    const fetchStoredTemperaments = async () => {
      try {
        const response = await axios.get(`https://lumitestserver-raisec.vercel.app/stored-Temperaments/${userId}`)
        setTemperament(response.data)
        if (response.data.length > 0) {
          setShowTemperament(true)
        }
      } catch (error) {
        console.error("Error fetching stored careers:", error)
      }
    }

    if (userId) {
      fetchStoredTemperaments()
    }
  }, [userId])


  // New useEffect for fetching the temperament info from the new endpoint
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://lumitestserver-raisec.vercel.app/get-additional-info")
        const data = response.data[0]

        if (data && data.temperament) {
          setAdditionalInfo([data.temperament])
          setListOfTraitProperties(data.temperament.listOfTraitProperties || "")
          setListOfSkillPreferencesProperties(data.temperament.listOfSkillPreferencesProperties || "")
          setListOfCareerPreferencesProperties(data.temperament.listOfCareerPreferencesProperties || "")
        } else {
          throw new Error("Invalid data structure")
        }
      } catch (error) {
        toast.error("Error fetching information.")
        console.error("Fetch Error:", error.response?.data || error)
      }
    }

    fetchData()
  }, [])
  

  const handleToggleDescription = (index) => {
    const newExpandedTemperaments = [...expandedTemperaments]
    newExpandedTemperaments[index] = !newExpandedTemperaments[index]
    setExpandedTemperaments(newExpandedTemperaments)
  }

  const handleToggleTemperament = () => {
    setShowTemperament(!showTemperament)
  }

  const handleDownloadPDF = () => {
    const input = document.getElementById("results-container")
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png")
      const pdf = new jsPDF("p", "mm", "a4")
      const imgProps = pdf.getImageProperties(imgData)
      const pdfWidth = pdf.internal.pageSize.getWidth()
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight)
      pdf.save(`results-${username}.pdf`)
    })
  }

  return showTemperament ? (
    <div className="Student-result">
      <div className={styles.Resultback}>
        <button onClick={() => window.history.back()} className={styles.ResultbackBtn}>
          Back
        </button>
      </div>
      <div onClick={handleToggleTemperament} className={styles.ResultHeading}>
        <div className={styles.headingdiv}>
          <h2>Congratulations {username}! Your results are</h2>
          <p>
            Answer all the following questions based on your preference. Try to avoid choosing the middle answers for
            more accurate results.
          </p>
        </div>
      </div>

      <div className={styles.studentTemperamentList}>
        <div className={styles.studentTemperamentcards}>
          <div className={styles.uppercard}>
            <div className={styles.uppercardleft}>
              {temperament.map((temp, index) => (
                <div className={styles.tempdescription} key={index}>
                  <h2 onClick={() => handleToggleDescription(index)}>{temp.temperamentName}</h2>
                  <div className={styles.descriptioninnerbox}>
                    <p style={{ fontSize: "0.95rem", position: "relative", top: "-25px" }}>{temp.description}</p>
                    <button style={{  position: "relative", top: "-25px" }} className={styles.startestbtn} onClick={() => navigate("/temperament-test")}>
                      Start Test
                    </button>
                  </div>
                </div>
              ))}
            </div>
            <div className={styles.uppercardright}>
              <img className={styles.tempprofileimg} src={tempimg || "/placeholder.svg"} alt="profile" />
            </div>
          </div>

          {additionalInfo.map((info, index) => (
            <div className={styles.lowercard}>
              <div className={styles.card} key={index}>
                {/* <h3>{info.traitsTitle}</h3> */}
                <h3>Most Prominent Traits</h3>
                <p style={{ fontSize: "0.9rem" }}>{info.traitsDescription}</p>
                <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
                  {listOfTraitPropertiesArray.slice(0, expandedCards.traits ? undefined : 2).map((trait, index) => (
                    <li key={index} style={{ padding: "1px 0", fontSize: "0.9rem", color: "#666", marginBottom: "2px", }}>
                      <strong>{index + 1}.</strong> {trait}
                    </li>
                  ))}
                  {listOfTraitPropertiesArray.length > 2 && !expandedCards.traits && (
                    <li
                      style={{ padding: "1px 0", fontSize: "0.9rem", color: "#666", cursor: "pointer" }}
                      onClick={() => setExpandedCards((prev) => ({ ...prev, traits: true }))}
                    >
                      ...
                    </li>
                  )}
                  {expandedCards.traits && (
                    <li
                      style={{ padding: "1px 0", fontSize: "0.9rem", color: "#666", cursor: "pointer" }}
                      onClick={() => setExpandedCards((prev) => ({ ...prev, traits: false }))}
                    >
                      Show less
                    </li>
                  )}
                </ul>
                <button className={styles.startestbtn} onClick={() => { /* Add your function here */ }} >read more</button>
              </div>

              <div className={styles.card}>
                {/* <h3>{info.skillPreferencesTitle}</h3> */}
                <h3>Skill Preferences</h3>
                <p style={{ fontSize: "0.9rem" }}>{info.skillPreferencesDescription}</p>
                <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
                  {listOfSkillPreferencesPropertiesArray
                    .slice(0, expandedCards.skills ? undefined : 2)
                    .map((skill, index) => (
                      <li key={index} style={{ padding: "1px 0", fontSize: "0.9rem", color: "#666" }}>
                        <strong>{index + 1}.</strong> {skill}
                      </li>
                    ))}
                  {listOfSkillPreferencesPropertiesArray.length > 2 && !expandedCards.skills && (
                    <li
                      style={{ padding: "1px 0", fontSize: "0.9rem", color: "#666", cursor: "pointer" }}
                      onClick={() => setExpandedCards((prev) => ({ ...prev, skills: true }))}
                    >
                      ...
                    </li>
                  )}
                  {expandedCards.skills && (
                    <li
                      style={{ padding: "1px 0", fontSize: "0.9rem", color: "#666", cursor: "pointer" }}
                      onClick={() => setExpandedCards((prev) => ({ ...prev, skills: false }))}
                    >
                      Show less
                    </li>
                  )}
                </ul>
                <button className={styles.startestbtn} onClick={() => { /* Add your function here */ }}>read more</button>
              </div>

              <div className={styles.card}>
                {/* <h3>{info.careerPreferncesTitle}</h3> */}
                <h3>Career Preferences</h3>
                <p style={{ fontSize: "0.9rem" }}>{info.careerPreferncesDescription}</p>
                <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
                  {listOfCareerPreferencesPropertiesArray
                    .slice(0, expandedCards.careers ? undefined : 2)
                    .map((career, index) => (
                      <li key={index} style={{ padding: "1px 0", fontSize: "0.9rem", color: "#666" }}>
                        <strong>{index + 1}.</strong> {career}
                      </li>
                    ))}
                  {listOfCareerPreferencesPropertiesArray.length > 2 && !expandedCards.careers && (
                    <li
                      style={{ padding: "1px 0", fontSize: "0.9rem", color: "#666", cursor: "pointer" }}
                      onClick={() => setExpandedCards((prev) => ({ ...prev, careers: true }))}
                    >
                      ...
                    </li>
                  )}
                  {expandedCards.careers && (
                    <li
                      style={{ padding: "1px 0", fontSize: "0.9rem", color: "#666", cursor: "pointer" }}
                      onClick={() => setExpandedCards((prev) => ({ ...prev, careers: false }))}
                    >
                      Show less
                    </li>
                  )}
                </ul>
                <button className={styles.startestbtn} onClick={() => { /* Add your function here */ }}>read more</button>
              </div>
            </div>
          ))}
        </div>
        <button onClick={handleDownloadPDF} className="temp-btn">
          Download
        </button>
      </div>
    </div>
  ) : (
    <div style={{ display: "flex", justifyContent: "center", width: "100%", height: "100vh", alignItems: "center" }}>
      No results found
    </div>
  )
}

export default TemperamentsResults

