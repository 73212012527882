import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import '../styles/Tests.css';
import Instruction from './Instruct';
import TestsAvailable from './TestsAvailable';
import RaisecResults from './RaisecResults';
import TemperamentResults from './TemperamentResults';
import PersonalityResults from './PersonalityResults';
import AllResults from './DisplayResults';

const Tests = () => {
  const navigate = useNavigate();
  const [raisecnumTests, setRaisecNumTests] = useState(null);
  const [temperamentnumTests, setTemperamentNumTests] = useState(null);
  const [personalitynumTests, setPersonalityNumTests] = useState(null);
  const [userId, setUserId] = useState('');
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(true);
  const [showInstructions, setShowInstructions] = useState(false);
  const [testname, setTestName] = useState('');
  const [activeTab, setActiveTab] = useState('Tests Available');

  useEffect(() => {
    const token = localStorage.getItem('token');
    const newUuid = localStorage.getItem('uuid');
    setUserId(newUuid || '');

    if (!token) navigate('/');
  }, [navigate]);

  const handleLogout = () => {
    localStorage.clear();
    toast.success('Logout successful');
    navigate('/');
  };

  useEffect(() => {
    const retrieveNumberOfDocs = async () => {
      try {
        const response = await axios.get('https://lumitestserver-raisec.vercel.app/retrieve-number-of-documents/');
        console.log('Number of documents:', response.data);
      } catch (error) {
        console.error('Error fetching number of documents:', error);
      }
    };

    const retrieveNumberOfRaisecDocs = async () => {
      if (userId) {
        try {
          console.log(`Making request with UUID: ${userId}`);
          const response = await axios.get(`https://lumitestserver-raisec.vercel.app/retrieve-number-of-raisec-documents/${userId}`);
          console.log('Number of raisec documents:', response.data);
          setRaisecNumTests(response.data);
        } catch (error) {
          console.error('Error fetching number of raisec documents:', error);
        }
      }
    };

    const retrieveNumberOfTemperamentDocs = async () => {
      if (userId) {
        try {
          const response = await axios.get(`https://lumitestserver-raisec.vercel.app/retrieve-number-of-temperament-documents/${userId}`);
          console.log('Number of temperament documents:', response.data);
          setTemperamentNumTests(response.data);
        } catch (error) {
          console.error('Error fetching number of temperament documents:', error);
        }
      }
    };

    const retrieveNumberOfPersonalityDocs = async () => {
      if (userId) {
        try {
          const response = await axios.get(`https://lumitestserver-raisec.vercel.app/p-retrieve-number-of-personality-documents/${userId}`);
          console.log('Number of personality documents:', response.data);
          setPersonalityNumTests(response.data);
        } catch (error) {
          console.error('Error fetching number of personality documents:', error);
        }
      }
    };

    retrieveNumberOfDocs();
    retrieveNumberOfRaisecDocs();
    retrieveNumberOfTemperamentDocs();
    retrieveNumberOfPersonalityDocs();
  }, [userId]);

  const tabs = [
    { name: 'Tests Available', component: TestsAvailable },
    { name: raisecnumTests || 0, component: RaisecResults },
    { name: personalitynumTests || 0, component: PersonalityResults },
    { name: temperamentnumTests || 0, component: TemperamentResults }
  ];

  const toggleSidebar = () => {
    setIsSidebarExpanded(!isSidebarExpanded);
  };


    const handleTestClick = (testname) => {
      navigate(`/instructions/${testname}`);//path paremeter
    };

    const renderActiveComponent = () => {
      if (activeTab === 'Profile') {
        return <AllResults />;
      }
      const activeTabData = tabs.find(tab => tab.name === activeTab);
      if (activeTabData) {
        const Component = activeTabData.component;
        return <Component handleTestClick={handleTestClick} />;
      }
      return null;
    };

  return (
    <div className="container">
      <div className={`sidebar ${isSidebarExpanded ? 'expanded' : 'collapsed'}`}>
        <button className="toggle-button" onClick={toggleSidebar}>
          {isSidebarExpanded ? '←' : '→'}
        </button>
        <div className='upper'>
          <div className="profile-section">
            <div className='profile-circle'>
              <h1 className="initial">{localStorage.getItem('username')?.[0].toUpperCase()}</h1>
            </div>
            <p className="username">{localStorage.getItem('username')}</p>
            <Link
              to="#"
              className="edit-profile"
              onClick={() => setActiveTab('Profile')}
            >
              Profile
            </Link>

          </div>
        </div>
        
        <div className="tabs">
          {tabs.map((tab, index) => (
            <button 
              key={index} 
              onClick={() => setActiveTab(tab.name)} 
              className={`tab-button ${activeTab === tab.name ? 'active' : ''}`}
            >
              {tab.name}
            </button>
          ))}
        </div>
        <button onClick={handleLogout} className="logout-button">Logout</button>
      </div>

      <div className={`render-active ${isSidebarExpanded ? 'expanded' : 'collapsed'}`}>
       {renderActiveComponent()}
    </div>
    </div>
  );
};

export default Tests;