import { useState, useEffect } from "react"
import { useNavigate, Link } from "react-router-dom"
import axios from "axios"
import profile from "../img/profile-img-grey.jpg"
import jsPDF from "jspdf"
import styled from "styled-components"
import html2canvas from "html2canvas"
import Airequestbutton from "../components/Airequestbutton"
import styles from "../styles/AllTests.module.css"
import RaisecResults from "./RaisecResults"
import PersonalityResults from "./PersonalityResults"
import TemperamentResults from "./TemperamentResults"
import icon1 from "../img/test-icon1.jpg"
import icon2 from "../img/test-icon2.jpg"
import icon3 from "../img/test-icon3.jpg"
import icon4 from "../img/test-icon4.jpg"
import icon5 from "../img/test-icon5.jpg"
import icon6 from "../img/test-icon6.jpg"

const ResContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`


function DisplayResults() {
  const [results, setResults] = useState([])
  const [careerList, setCareerList] = useState([])
  const [whattheydolist, setWhatTheyDoList] = useState([])
  const [temperament, setTemperament] = useState([])
  const [personality, setPersonality] = useState([])
  const [userId, setUserId] = useState("")
  const [username, setUsername] = useState("")
  // const [_id, setId] = useState('');
  const [email, setEmail] = useState("")
  const [expandedTemperaments, setExpandedTemperaments] = useState([])
  const [expandedPersonality, setExpandedPersonality] = useState([])
  const [showResults, setShowResults] = useState(true)
  const [showCareerList, setShowCareerList] = useState(true)
  const [showTemperament, setShowTemperament] = useState(true)
  const [showPersonality, setShowPersonality] = useState(true)
  const [expandedCards, setExpandedCards] = useState({})
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedTest, setSelectedTest] = useState("")

  const navigate = useNavigate()

  useEffect(() => {
    const token = localStorage.getItem("token")
    if (!token) {
      // Redirect to login page if user is not logged in
      navigate("/")
    } else {
      // Fetch user ID from session storage
      const newUuid = localStorage.getItem("uuid")
      const username = localStorage.getItem("username")
      // const _id = localStorage.getItem('_id');
      const email = localStorage.getItem("email")
      setUserId(newUuid)
      setUsername(username)
      // setId(_id);
      setEmail(email)
    }
  }, [navigate])

  useEffect(() => {
    const fetchStoredResults = async () => {
      try {
        const response = await axios.get(`https://lumitestserver-raisec.vercel.app/stored-raisec-results/${userId}`)
        setResults(response.data)
      } catch (error) {
        console.error("Error fetching stored results:", error)
      }
    }

    const fetchStoredCareers = async () => {
      try {
        const response = await axios.get(`https://lumitestserver-raisec.vercel.app/stored-career-results/${userId}`)
        setCareerList(response.data)
      } catch (error) {
        console.error("Error fetching stored careers:", error)
      }
    }

    const fetchStoredTemperaments = async () => {
      try {
        const response = await axios.get(`https://lumitestserver-raisec.vercel.app/stored-Temperaments/${userId}`)
        setTemperament(response.data)
      } catch (error) {
        console.error("Error fetching stored temperaments:", error)
      }
    }

    const fetchStoredPersonality = async () => {
      try {
        const response = await axios.get(`https://lumitestserver-raisec.vercel.app/p-stored-Personalities/${userId}`)
        setPersonality(response.data)
      } catch (error) {
        console.error("Error fetching stored personalities:", error)
      }
    }

    if (userId) {
      fetchStoredResults()
      fetchStoredCareers()
      fetchStoredTemperaments()
      fetchStoredPersonality()
    }
  }, [userId])

  const handleToggleDescription = (index) => {
    const newExpandedTemperaments = [...expandedTemperaments]
    newExpandedTemperaments[index] = !newExpandedTemperaments[index]
    setExpandedTemperaments(newExpandedTemperaments)
  }

  const handleTogglePersonalityDescription = (index) => {
    const newExpandedPersonality = [...expandedPersonality]
    newExpandedPersonality[index] = !newExpandedPersonality[index]
    setExpandedPersonality(newExpandedPersonality)
  }

  const handleToggleResults = () => {
    setShowResults(!showResults)
  }

  const handleToggleCareerList = () => {
    setShowCareerList(!showCareerList)
  }

  const handleToggleTemperament = () => {
    setShowTemperament(!showTemperament)
  }

  const handleTogglePersonality = () => {
    setShowPersonality(!showPersonality)
  }

  const handleDownloadPDF = () => {
    const input = document.getElementById("results-container")
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png")
      const pdf = new jsPDF("p", "mm", "a4")
      const imgProps = pdf.getImageProperties(imgData)
      const pdfWidth = pdf.internal.pageSize.getWidth()
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight)
      pdf.save(`results-${username}.pdf`)
    })
  }

  const toggleReadMore = (index) => {
    setExpandedCards((prev) => ({
      ...prev,
      [index]: !prev[index],
    }))
  }

  const pickresults = (test) => {
    switch (test) {
      case "raisec-test":
        return <RaisecResults />
      case "personality-test":
        return <PersonalityResults />
      case "temperament-test":
        return <TemperamentResults />
      default:
        return []
    }
  }

  const openModal = (test) => {
    setSelectedTest(test)
    pickresults(test)
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const icons = [icon1, icon2, icon3, icon4, icon5, icon6]

  return (
    <div className={styles.StudentResult}>
      <style>
        {`
          @keyframes slideUp {
            from {
              transform: translateY(100%);
            }
            to {
              transform: translateY(0);
            }
          }
        `}
      </style>
      <div className={styles.ResultHeading}>
        <div className={styles.ProfileIfno}>
          <div className="result-profile-img">
            <img src={profile || "/placeholder.svg"} alt="profile" />
            <h2>{email}</h2>
          </div>

          <div className="result-profile-inf">
            <h2>{email}</h2>
            <p>ID: lum-{userId}-nate</p>
            <h2>{username}</h2>
          </div>
        </div>
      </div>

      <div className="test-cards">
        <div className="test-card">
          <img src={icon1 || "/placeholder.svg"} alt="Raisec Test" className="test-card-img" />
          <h3>Raisec Test Results</h3>
          {results.slice(0, 3).map((res, index) => (
            <strong key={index}>
              {index + 1}. {res.area}
            </strong>
          ))}

          <button className="test-res-button" onClick={() => openModal("raisec-test")}>
            Explore results
          </button>
          <Link to="#" className="read-more">
            Read More
          </Link>
        </div>

        <div className="test-card">
          <img src={icon2 || "/placeholder.svg"} alt="Personality Test" className="test-card-img" />
          <h3>Personality Test Results</h3>
          {personality.map((perso, index) => (
            <strong key={index}>{perso.personalityName}</strong>
          ))}
          <button className="test-res-button" onClick={() => openModal("personality-test")}>
            Explore results
          </button>
          <Link to="#" className="read-more">
            Read More
          </Link>
        </div>

        <div className="test-card">
          <img src={icon3 || "/placeholder.svg"} alt="Temperament Test" className="test-card-img" />
          <h3>Temperament Test Results</h3>
          {temperament.map((temp, index) => (
            <strong key={index}>{temp.temperamentName}</strong>
          ))}
          <button className="test-res-button" onClick={() => openModal("temperament-test")}>
            Explore results
          </button>
          <Link to="#" className="read-more">
            Read More
          </Link>
        </div>
      </div>

      {isModalOpen && (
        <div
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-end",
            zIndex: 1000,
            transition: "all 0.3s ease-in-out",
          }}
          onClick={closeModal}
        >
          <div
            style={{
              position: "relative",
              backgroundColor: "#f2f5fe",
              width: "100%",
              height: "100%",
              boxShadow: "0px -4px 10px rgba(0, 0, 0, 0.1)",
              animation: "slideUp 0.5s ease-out forwards",
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <div style={{ textAlign: "right" }}>
              <button
                onClick={closeModal}
                style={{
                  background: "none",
                  border: "none",
                  fontSize: "24px",
                  cursor: "pointer",
                }}
              >
                &times;
              </button>
            </div>
              <ResContainer>
                {pickresults(selectedTest)}
              </ResContainer>

          </div>
        </div>
      )}

      <Airequestbutton />
    </div>
  )
}

export default DisplayResults

